import { Injectable } from "@angular/core";
import { AppProxy } from "./app.proxy";
import { User } from "../Models/User";

import { ParentDetails } from "../Models/ParentDetails";
import { promise } from "protractor";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { dailog } from "./Additions/dailog/dailog";
import { Debit } from "../Models/Debit";
import {
  UpdateChildDetails,
  DebitAddition
} from "../Models/UpdateChildrenDetails";
import { ChildrenDetails } from "../Models/ChildrenDetails";
import { LastDebit } from "../Models/LastDebit";
import { CodeTable } from "../Models/CodeTable";
import { ReturnStatement } from "@angular/compiler";
// import { AnimateTimings } from '@angular/core/src/animation/dsl';
import { Family } from "../Models/Family";
import { DebitDetailsToMonth } from "../Models/DebitDetailsToMonth";
import { paymentDetails } from "../Models/paymentDetails";
import { Setting } from "../Models/setting";
import { Year } from "../Models/Year";
import { Classes } from "../Models/Classes";
import { Rate } from "../Models/Rate";
import { Association } from "../Models/Association";
import { FormControl } from "@angular/forms";
import { Branch } from "../Models/Branch";
import { DebitDay } from "../Models/DebitDay";
import { TransportRoute } from "../Models/TransportRoute";
import * as XLSX from "xlsx";
import { ImportToExcel } from "../Models/ImportToExcel";
import { FamilyCredit } from "../Models/FamilyCredit";
import { FamilyContacts } from "../Models/FamilyContacts";
import { Default } from "../Models/Default";
import { Router } from "@angular/router";

import { Discont } from "../Models/Discont";
import { ReportsDet } from "src/Models/ReportsDet";
import { FieldsToChange } from "src/Models/FieldsToChange";
import { pdfByClass } from "src/Components/Reports/children-list/children-list.component";
@Injectable()
export class WindowRef {
  constructor() { }

  getNativeWindow() {
    return window;
  }
}
@Injectable()
export class ServService {
  // for debit filter
  static debitFilterState: any = null;
  static debitSortState: any = null;
  static debitGroupState: any = [];
  static debitColState: any = null;
  //
  nvExpDate: string;
  KupatCholim: CodeTable;
  lReportSubjectType: CodeTable;
  bfromParentsList: boolean = false;
  bfromParentsBalance: boolean = false;
  RateTypeForPaymentMethod: CodeTable;
  CreditStatusType: CodeTable[];
  lTransportRouteTypes: any;
  FullYearList: CodeTable;
  globalColor: string;
  user: User;
  debit: Debit;
  pDetails: ParentDetails;
  paymentdetails: paymentDetails;
  udChildDetails: UpdateChildDetails;
  cDetails: ChildrenDetails;
  lDebit: LastDebit;
  currentFamily: Family;
  currentiFamilyId: number = -1;
  lCities: CodeTable;
  lPaymentMethod: CodeTable;
  lBanks: CodeTable;
  lStatuses: CodeTable;
  lRateType: CodeTable;
  lAssociation: CodeTable;
  AssociationDirectDebit: CodeTable;//הוק
  AssociationCredit: CodeTable;//אשראי
  AssociationCreditTemp: CodeTable;//אשראי
  lDebitDay: CodeTable;
  lIdentityType: CodeTable;
  lMealsRate: CodeTable;
  lTransRate: CodeTable;
  lTransportRouteForth: CodeTable;
  lTransportRouteBack: CodeTable;
  lransportRouteFriday: CodeTable;
  lcDetails: Array<ChildrenDetails>
  lTuitionRate: CodeTable;
  lInstitution: CodeTable;
  lClass: CodeTable;
  lYears: Array<CodeTable>;
  lBranches: CodeTable;
  lChildStatusType: CodeTable;
  lCreditMethodType: CodeTable;
  lCreditMethodTypeForRefund: CodeTable;
  lRelationshipType: CodeTable;
  lFamilyStatusType: CodeTable;
  lReceiptType: CodeTable;
  public backgroundColor: any[];
  visible: any;
  lDetailsToMonth: DebitDetailsToMonth;
  //דיאלוג לשגיאת שרת
  fileNameDialogRef: MatDialogRef<dailog>;
  isFormPaymentVisible: boolean;
  newPDetails: paymentDetails;
  iDebitDay: number;
  dDebitMonth: Date;
  iCreditDay: number;
  dCreditMonth: Date;
  bfromDebit: Boolean = false;
  bfromParentsAndChild: Boolean = false;
  bfromCredit: Boolean = false;
  visibleCreditCard: boolean;
  visibleDirectDebit: boolean;
  iYearId: number = 0; //for reports
  lStatus: Array<number> = new Array<number>()//for reports
  visibleStatus = true; //for comboBox in families
  iChildYearId: number;
  iChildId: number;
  default1: Default = new Default();
  FilterFamiliesArray: Array<any> = new Array<any>();
  SortFamiliesArray: Array<any> = new Array<any>();
  CheckBoxFamiliesArray: Array<any> = new Array<any>();
  DebitStatusType: CodeTable;
  isReadOnly: boolean;
  FilterCreditsArray: Array<any> = new Array<any>();
  SortCreditsArray: Array<any> = new Array<any>();
  //====================
  // childDetails;
  constructor(
    private proxy: AppProxy,
    private dailog: MatDialog,
    private route: Router
  ) {
    this.lTransportRouteForth = new CodeTable();
    this.lTransportRouteBack = new CodeTable();
    this.lransportRouteFriday = new CodeTable();
    this.visible = true;
    this.backgroundColor = [
      "#ff9e00",
      "#aacf37",
      "#00b0e8",
      "#cf22a1",
      "#00cfc9"
    ];
    this.globalColor = "#634ea4";
    this.pDetails = new ParentDetails();
    this.udChildDetails = new UpdateChildDetails();
    // this.user = new User()
    this.lDebit = new LastDebit();
    this.currentFamily = new Family();
    // this.visible=true
    // this.GetCodeTable()
    this.visible = true;
    this.newPDetails = new paymentDetails();
    this.isFormPaymentVisible = false;

    this.visibleCreditCard = false;
    this.visibleDirectDebit = false;
    this.visibleStatus = true;
    // this.default = new Default();
    this.default1.lFamilyStatus = new Array<number>();
  }

  ngOnInit() {
    // alert(this.route.url);
  }

  Login(nvUserName: string, nvPassword: string, ip): Promise<any> {
    return this.proxy
      .post("Login", {
        nvUserName: nvUserName,
        nvPassword: nvPassword,
        nvIP: ip
      })
      .then(res => {
        if (res.iUserId != -1) {
          this.user = res;
          localStorage.setItem("user", JSON.stringify(this.user));
          // this.GetDefault();
          return res;
        }
        return res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  ////debit
  InsAutomaticCredit(
    dCreditMonth: Date,
    iCreditDay: number,
    iCreditMethodType: number
  ): Promise<any> {
    return this.proxy
      .post("InsAutomaticCredit", {
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId,
        dDebitMonth: dCreditMonth,
        iDebitDay: iCreditDay,
        iCreditMethodType: iCreditMethodType
      })
      .then(res => {
        if (res) {
          let data: string[] = [];
          data.push("בוצע בהצלחה ");
          this.dailog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data: data
          });
        }
        // else {
        //   this.dailog.open(dailog, {
        //     hasBackdrop: true,
        //     height: '200px',
        //     width: '300px',
        //     data: 'ארעה שגיאה בלתי צפויה '
        //   });
        //   return null;
        // }
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  createFileMasav(
    dCreditMonth: Date,
    iCreditDay: number,
    iCreditMethodType: number
  ): Promise<any> {
    return this.proxy
      .post("CreateFileMasav", {
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId,
        dCreditMonth: dCreditMonth,
        iCreditDay: iCreditDay,
        iCreditMethodType: iCreditMethodType
      })
      .then(res => {
        console.log("createFileMasav", res);
        return res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  PeleCardCharge(
    dCreditMonth: Date,
    iCreditDay: number,
    iCreditMethodType: number
  ): Promise<any> {
    // return this.proxy
    //   .post("PeleCardCharge", {
    //     iUserId: this.user.iUserId,
    //     nvGuide: this.user.nvGuide,
    //     iInstitutionId: this.user.iInstitutionId,
    //     dCreditMonth: dCreditMonth,
    //     iCreditDay: iCreditDay,
    //     iCreditMethodType: iCreditMethodType
    //   })
    //  int iCreditDay, DateTime dCreditMonth,int iCCCompanyType
    return this.proxy
      .post("ExecuteCharge", {
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId,
        iCreditDay: iCreditDay,
        dCreditMonth: dCreditMonth,
        iCCCompanyType:60
      })
      .then(res => {
        let data: string[] = [];
        // data.push("גביה בוצעה בהצלחה");
        // if (res == true) {
        //   this.dailog.open(dailog, {
        //     hasBackdrop: true,
        //     height: "200px",
        //     width: "300px",
        //     data: data
        //   });
        // }
        if (res > 0) {
          data.push("גביה בוצעה בהצלחה");
          this.dailog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data: data
          });
        }
        else if (res == -2) {
          data.push("הגביה בביצוע, נא להמתין בסבלנות.");
          this.dailog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data: data
          });
        }
        return res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  ZCreditCharge(dCreditMonth: Date, iCreditDay: number): Promise<any> {
    // return this.proxy
    //   .post("ZCreditCharge", {
    //     iUserId: this.user.iUserId,
    //     nvGuide: this.user.nvGuide,
    //     iInstitutionId: this.user.iInstitutionId,
    //     dCreditMonth: dCreditMonth,
    //     iCreditDay: iCreditDay
    //   })
    return this.proxy
      .post("ExecuteCharge", {
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId,
        iCreditDay: iCreditDay,
        dCreditMonth: dCreditMonth,
        iCCCompanyType:61
      })
      .then(res => {
        let data: string[] = [];
        if (res > 0) {
          data.push("גביה בוצעה בהצלחה");
          this.dailog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data: data
          });
        }
        else if (res == -2) {
          data.push("הגביה בביצוע, נא להמתין בסבלנות.");
          this.dailog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data: data
          });
        }
        return res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }

 

  GetCredit(
    dCreditMonth: Date,
    iCreditDay: number,
    lStatusType,
    bManagmentCheck,
    iCreditMethodType?,
    bRefused?,
    bUpdatingBack?
  ): Promise<any> {
    return this.proxy
      .post("GetCredit", {
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId,
        iFamilyId: this.currentiFamilyId,
        dCreditMonth: dCreditMonth,
        iCreditDay: iCreditDay,
        lStatusType: lStatusType,
        iCreditMethodType,
        bRefused: bRefused,
        bUpdatingBack: bUpdatingBack,
        bManagmentCheck: bManagmentCheck
      })
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }

  GetAssociationCredit(): Promise<any> {
    return this.proxy
      .post("GetAssociationCredit", {
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId,
        iBranchId: this.user.iBranchId
      })
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }

  ChargeAgain(dCreditMonth: Date, iCreditDay: number) {
    return this.proxy
      .post("ChargeAgain", {
        iCreditDay: iCreditDay,
        dCreditMonth: dCreditMonth,
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId

      })
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }

  GetUpdateCheck(iCheckId: number): Promise<any> {
    return this.proxy
      .post("GetCheck", {
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iCheckId: iCheckId,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log("err-----", err);
        return false;
      });
  }
  GetUpdateCredit(CreditId: number): Promise<any> {
    return this.proxy
      .post("GetUpdateCredit", {
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iCreditId: CreditId,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }

  GetPaymentDetailsReport(): Promise<any> {
    return this.proxy
      .post("GetPaymentDetailsReport", {
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }

  GetDebit(dDebitMonth: Date, iDebitDay: number): Promise<any> {
    return this.proxy
      .post("GetDebit", {
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId,
        dDebitMonth: dDebitMonth,
        iDebitDay: iDebitDay
      })
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  InsDebit(dDebitMonth: Date, iDebitDay: number): Promise<any> {
    return this.proxy
      .post("InsDebit", {
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId,
        dDebitMonth: dDebitMonth,
        iDebitDay: iDebitDay
      })
      .then(res => {
        let data: string[] = [];
        data.push("בוצע בהצלחה ");
        if (res)
          this.dailog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data: data
          });
        return res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }

  GetMonthesCodeTable(iInstitutionId: number): Promise<any> {
    return this.proxy
      .post("GetMonthesCodeTable", {
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: iInstitutionId
      })
      .then(res => {
        if (res != null) {
          return res;
        } else {
          // this.dailog.open(dailog, {
          //   hasBackdrop: true,
          //   height: '200px',
          //   width: '300px',
          //   data: 'ארעה שגיאה בלתי צפויה '
          // });
          return null;
        }
      });
  }
  ////////family
  getFamilys(lStatus: Array<number>): Promise<any> {
    return this.proxy
      .post("GetFamilys", {
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId,
        lChildStatusTypes: lStatus,
        iBranchId: this.user.iBranchId
      })
      .then(res => {
        if (res != null) {
          // console.log("families  "+ JSON.stringify(res))
          return res;
        } else {
          // this.dailog.open(dailog, {
          //   hasBackdrop: true,
          //   height: '200px',
          //   width: '300px',
          //   data: 'ארעה שגיאה בלתי צפויה '
          // });
          return null;
        }
      });
  }
  GetParentDetails(iUserId: number, iFamilyId: number): Promise<any> {
    return this.proxy
      .post("GetParentDetails", {
        iUserId: iUserId,
        iFamilyId: iFamilyId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        if (res) {
          this.pDetails = res;
          return this.pDetails;
        } else {
          // this.dailog.open(dailog, {
          //   hasBackdrop: true,
          //   height: '200px',
          //   width: '300px',
          //   data: 'ארעה שגיאה בלתי צפויה '
          // });
          return null;
        }
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  saveAndInsertParentDetails(
    iUserId: number,
    iFamilyId: number,
    pDetails: ParentDetails
  ): Promise<any> {
    return this.proxy
      .post("saveAndInsertParentDetails", {
        iUserId: iUserId,
        iFamilyId: iFamilyId,
        parentDetails: pDetails,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        let data: string[] = [];
        data.push("פרטי הורים עודכנו בהצלחה");
        if (res) {
          this.currentiFamilyId = res;
          this.dailog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data: data
          });
        } else {
          // this.dailog.open(dailog, {
          //   hasBackdrop: true,
          //   height: '200px',
          //   width: '300px',
          //   data: 'ארעה שגיאה בלתי צפויה '
          // });
          return null;
        }
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  //update child details
  GetChildDetails(iUserId: number, iChildId: number): Promise<any> {
    return this.proxy
      .post("GetChildDetails", {
        iUserId: iUserId,
        iChildId: iChildId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId,
        iYearId: this.iYearId
      })

      .then(res => {
        console.log("year" + this.iYearId);
        if (res) {
          // res.dBeginDate = new Date(res.dBeginDate);
          // res.dBirthDate = new Date(res.dBirthDate);
          // res.dEndDate = new Date(res.dEndDate);
          return res;
        } else {
          // this.dailog.open(dailog, {
          //   hasBackdrop: true,
          //   height: "200px",
          //   width: "300px"
          // });
          return null;
        }
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  PrintToPDF(lReportsDet, string, title, filePath): Promise<any> {
    return this.proxy
      .post("PrintToPDF", {
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        lReportsDet: lReportsDet,
        dt: string,
        title: title,
        nvFilePath: filePath
      })

      .then(res => {
        return res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  //get debit adittions of child
  GetDebitAddition(iUserId: number, dFromDate: Date, ): Promise<any> {
    // console.log("children det" +JSON.stringify( this.proxy.post("GetChildrenDetails", {iUserId:iUserId,iFamilyId:iFamilyId,nvGuide:this.user.nvGuide,iInstitutionId:this.user.iInstitutionId,iYearId:5778})))
    return this.proxy
      .post("GetDebitAddition", {
        dFromDate: dFromDate,
        iChildId: this.iChildId,
        iUserId: iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        console.log(res);
        return res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  DeleteFamily(iFamilyId: number): Promise<any> {
    return this.proxy
      .post("DeleteFamily", {
        iFamilyId: iFamilyId,
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        let data: string[] = [];
        data.push("בוצע בהצלחה ");
        console.log(res);
        this.dailog.open(dailog, {
          hasBackdrop: true,
          height: "200px",
          width: "300px",
          data: data
        });
        return res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }

  //report for childrenList
  GetChildrenReport(lStatus) {
    return this.proxy
      .post("GetChildrenReport", {
        lChildStatusTypes: lStatus,
        iYearId: this.iYearId,
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId,
        iBranchId: this.user.iBranchId
      })
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }

  GetParentsReport(lStatus) {
    return this.proxy
      .post("GetParentsReport", {
        lChildStatusTypes: lStatus,
        iYearId: this.iYearId,
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId,
        iBranchId: this.user.iBranchId
      })
      .then(res => {
        console.log(JSON.stringify(res));
        return res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  // GetGraduateAndOutgoingReport(iUserId, nvGuide, iInstitutionId, iBranchId)
  GetGraduateAndOutgoingReport() {
    return this.proxy
      .post("GetGraduateAndOutgoingReport", {
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId,
        iBranchId: this.user.iBranchId
      })
      .then(res => {
        console.log(JSON.stringify(res));
        return res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }


  //children details
  GetChildrenDetails(
    iUserId: number,
    iFamilyId: number,
    lStatus
  ): Promise<any> {
    // console.log("children det" +JSON.stringify( this.proxy.post("GetChildrenDetails", {iUserId:iUserId,iFamilyId:iFamilyId,nvGuide:this.user.nvGuide,iInstitutionId:this.user.iInstitutionId,iYearId:5778})))
    return this.proxy
      .post("GetChildrenDetails", {
        iUserId: iUserId,
        iFamilyId: iFamilyId,
        nvGuide: this.user.nvGuide,
        iYearId: this.iYearId,
        iInstitutionId: this.user.iInstitutionId,
        lChildStatusTypes: lStatus,
        iBranchId: this.user.iBranchId
      })
      .then(res => {
        console.log(res);
        return res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }

  UpdDebitDay(iDebitDay, iFamilyId) {
    return this.proxy
      .post("UpdDebitDay", {
        iUserId: this.user.iUserId,
        iFamilyId: iFamilyId,
        iDebitDay: iDebitDay,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        console.log(res);
        return res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  ////last debit
  GetLastDebit(
    dFromDate: Date,
    iUserId: number,
    iFamilyId: number
  ): Promise<any> {
    dFromDate = new Date(dFromDate);
    return this.proxy
      .post("GetLastDebit", {
        dFromDate: dFromDate,
        iUserId: iUserId,
        iFamilyId: iFamilyId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  GetCodeTable(): Promise<any> {
    return this.proxy
      .post("GetCodeTables", {
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId,
        iBranchId: this.user.iBranchId
      })
      .then(res => {
        if (res) {
          this.lCities = new CodeTable();
          this.lCities = res.filter(
            item => item.nvTableName == "cityType"
          )[0].lCodeTable;
          this.lIdentityType = new CodeTable();
          this.lIdentityType = res.filter(
            item => item.nvTableName == "IdentityType"
          )[0].lCodeTable;
          this.lPaymentMethod = new CodeTable();
          this.lPaymentMethod = res.filter(
            item => item.nvTableName == "PaymentMethodType"
          )[0].lCodeTable;
          this.lBanks = new CodeTable();
          this.lBanks = res.filter(
            item => item.nvTableName == "BankType"
          )[0].lCodeTable;
          this.lStatuses = new CodeTable();
          this.lStatuses = res.filter(
            item => item.nvTableName == "PaymentMethodStatusType"
          )[0].lCodeTable;
          this.lRateType = new CodeTable();
          this.lRateType = res.filter(
            item => item.nvTableName == "RateType"
          )[0].lCodeTable;
          this.lAssociation = new CodeTable();
          this.lAssociation = res.filter(
            item => item.nvTableName == "AssociationType"
          )[0].lCodeTable;
          console.log(this.lAssociation);
          this.lDebitDay = new CodeTable();
          this.lDebitDay = res.filter(
            item => item.nvTableName == "DebitDay"
          )[0].lCodeTable;
          this.lMealsRate = new CodeTable();
          this.lMealsRate = res.filter(
            item => item.nvTableName == "MealsRate"
          )[0].lCodeTable;
          this.CreditStatusType = [];
          this.CreditStatusType = res.filter(
            item => item.nvTableName == "CreditStatusType"
          )[0].lCodeTable;

          this.lTransRate = new CodeTable();
          this.lTransRate = res.filter(
            item => item.nvTableName == "TransRate"
          )[0].lCodeTable;
          this.lReportSubjectType = new CodeTable();
          this.lReportSubjectType = res.filter(
            item => item.nvTableName == "ReportSubjectType"
          )[0].lCodeTable;

          this.lBranches = new CodeTable();
          this.lBranches = res.filter(
            item => item.nvTableName == "Branches"
          )[0].lCodeTable;
          this.lTransportRouteTypes = new CodeTable();
          this.lTransportRouteTypes = res.filter(
            item => item.nvTableName == "TransportRouteType"
          )[0].lCodeTable;
          this.lYears = new Array<CodeTable>();
          this.lYears = res.filter(
            item => item.nvTableName == "Year"
          )[0].lCodeTable;
          this.lCreditMethodType = new CodeTable();
          this.lCreditMethodType = this.user.iUserType != 42 ? res.filter(
            item => item.nvTableName == "CreditMethodType"
          )[0].lCodeTable : res.filter(
            item => item.nvTableName == "CreditMethodType"
          )[0].lCodeTable.filter(c => [14, 49, 50, 76].includes(c.id));

          this.lCreditMethodTypeForRefund = new CodeTable();
          this.lCreditMethodTypeForRefund = res.filter(
            item => item.nvTableName == "CreditMethodTypeForRefund"
          )[0].lCodeTable;

          this.lRelationshipType = new CodeTable();
          this.lRelationshipType = res.filter(
            item => item.nvTableName == "RelationshipType"
          )[0].lCodeTable;
          this.lFamilyStatusType = new CodeTable();
          this.lFamilyStatusType = res.filter(
            item => item.nvTableName == "FamilyStatusType"
          )[0].lCodeTable;
          this.RateTypeForPaymentMethod = new CodeTable();
          this.RateTypeForPaymentMethod = res.filter(
            item => item.nvTableName == "RateTypeForPaymentMethod"
          )[0].lCodeTable;
          this.KupatCholim = new CodeTable();
          this.KupatCholim = res.filter(
            item => item.nvTableName == "KupatCholim"
          )[0].lCodeTable;
          this.DebitStatusType = new CodeTable();
          this.DebitStatusType = res.filter(
            item => item.nvTableName == "DebitStatusType"
          )[0].lCodeTable;

          if (this.lYears.length > 0) this.lChildStatusType = new CodeTable();
          this.lChildStatusType = res.filter(
            item => item.nvTableName == "ChildStatusType"
          )[0].lCodeTable;
          // console.log("iYearId" +this.iYearId)
          this.FullYearList = new CodeTable();
          this.FullYearList = res.filter(
            item => item.nvTableName == "FullYearList"
          )[0].lCodeTable;
          // אשראי
          this.AssociationCredit = new CodeTable();
          this.AssociationCredit = res.filter(
            item => item.nvTableName == "AssociationCredit"
          )[0].lCodeTable;
          console.log(this.AssociationCredit);
          // הוק
          this.AssociationDirectDebit = new CodeTable();
          this.AssociationDirectDebit = res.filter(
            item => item.nvTableName == "AssociationDirectDebit"
          )[0].lCodeTable;
          console.log(this.AssociationDirectDebit);
          this.lReceiptType = new CodeTable();
          this.lReceiptType = res.filter(
            item => item.nvTableName == "ReceiptType"
          )[0].lCodeTable;
          console.log(this.AssociationDirectDebit);
        } else {
          // this.dailog.open(dailog, {
          //   hasBackdrop: true,
          //   height: '200px',
          //   width: '300px',
          //   data: 'ארעה שגיאה בלתי צפויה '
          // });
          return null;
        }
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }

  GetTuitionRateCodeTable() {
    return this.proxy
      .post("GetTuitionRateCodeTable", {
        iInstitutionId: this.user.iInstitutionId,
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide
      })
      .then(res => {
        this.lTuitionRate = new CodeTable();
        this.lTuitionRate = res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  GetFamilyCodeTable() {
    return this.proxy
      .post("GetFamilyCodeTable", {
        iInstitutionId: this.user.iInstitutionId,
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iBranchId: this.user.iBranchId
      })
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }

  UpdCheckAmount(lDebitAddition) {
    return this.proxy
      .post("UpdCheckAmount", {
        lCheckAmount: lDebitAddition,
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        let data: string[] = [];
        data.push("פרטי שיקים עודכנו בהצלחה");
        if (res)
          this.dailog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data: data
          });
        return res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  UpdCreditStatus(lCredits, iStatusType) {
    return this.proxy
      .post("UpdCreditStatus", {
        lCredits: lCredits,
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId,
        iStatusType: iStatusType
      })
      .then(res => {
        if (res) {
          let data1: string[] = [];
          if (iStatusType == 34)//הוק
            data1.push("פרטי הו'ק עודכנו בהצלחה");
          else
            data1.push("פרטי שיקים עודכנו בהצלחה");
          this.dailog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data: data1
          });
          return res;
        }
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  UpdCheckStatus(lCredits, iStatusType) {
    return this.proxy
      .post("UpdCheckStatus", {
        lChecks: lCredits,
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId,
        iStatusType: iStatusType
      })
      .then(res => {
        if (res) {
          let data1: string[] = [];
          if (iStatusType == 34)//הוק
            data1.push("פרטי הו'ק עודכנו בהצלחה");
          else
            data1.push("פרטי שיקים עודכנו בהצלחה");
          this.dailog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data: data1
          });
          return res;
        }
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  GetCheckList(dCreditMonth) {
    return this.proxy
      .post("GetCheckList", {
        dCreditMonth: dCreditMonth,
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  GetCheckManagement(dCreditMonth, iStatusType) {
    return this.proxy
      .post("GetCheckManagement", {
        dCreditMonth: dCreditMonth,
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId,
        iStatusType: iStatusType
      })
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }

  saveUpdateChild(updateChildDetails: UpdateChildDetails): Promise<any> {
    if (updateChildDetails.dBeginDate)
      updateChildDetails.dBeginDate = new Date(updateChildDetails.dBeginDate);
    if (updateChildDetails.dBirthDate)
      updateChildDetails.dBirthDate = new Date(updateChildDetails.dBirthDate);
    if (updateChildDetails.dEndDate)
      updateChildDetails.dEndDate = new Date(updateChildDetails.dEndDate);
    if (updateChildDetails.dRegisteredDate)
      updateChildDetails.dRegisteredDate = new Date(
        updateChildDetails.dRegisteredDate
      );
    if (updateChildDetails.dTranspRateUpdatedToDate)
      updateChildDetails.dTranspRateUpdatedToDate = new Date(
        updateChildDetails.dTranspRateUpdatedToDate
      );
    if (updateChildDetails.dTuitionRateUpdatedFromDate)
      updateChildDetails.dTuitionRateUpdatedFromDate = new Date(
        updateChildDetails.dTuitionRateUpdatedFromDate
      );
    if (updateChildDetails.nDiscountAmount != null && updateChildDetails.nDiscountAmount.toString() == "")
      updateChildDetails.nDiscountAmount = null;
    if (updateChildDetails.nDiscountPerc != null && updateChildDetails.nDiscountPerc.toString() == "")
      updateChildDetails.nDiscountPerc = null;
    if (updateChildDetails.nMealsAmount != null && updateChildDetails.nMealsAmount.toString() == "")
      updateChildDetails.nMealsAmount = null;
    return this.proxy
      .post("InsUpdChildDetails", {
        iUserId: this.user.iUserId,
        updateChildDetails: updateChildDetails,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId,
        iYearId: this.iYearId
      })
      .then(res => {
        if (res) {
          let data: string[] = [];
          data.push("פרטי תלמיד עדכנו בהצלחה ! ");
          this.dailog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data: data
          });
          return res;
        }
        // else this.dailog.open(dailog, {
        //   hasBackdrop: true,
        //   height: '200px',
        //   width: '300px',
        //   data: 'ארעה שגיאה בלתי צפויה '
        // });
      })
      .catch(err => {
        console.log("err-----" + err);
        return -1;
      });
  }

  InsUpdDebitAddition(
    lDebitAddition: Array<DebitAddition>,
    iFamilyId: number
  ): Promise<any> {
    for (let i = 0; i < lDebitAddition.length; i++) {
      lDebitAddition[i].dDebitMonth = new Date(lDebitAddition[i].dDebitMonth);
    }

    return this.proxy
      .post("InsUpdDebitAddition", {
        iChildYearId: this.iChildYearId,
        iUserId: this.user.iUserId,
        lDebitAddition: lDebitAddition,
        iFamilyId: iFamilyId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        let data: string[] = [];
        data.push("פרטי תלמיד עדכנו בהצלחה ! ");
        if (res) {
          this.dailog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data: data
          });
          return res;
        }
        // else this.dailog.open(dailog, {
        //   hasBackdrop: true,
        //   height: '200px',
        //   width: '300px',
        //   data: 'ארעה שגיאה בלתי צפויה '
        // });
      })
      .catch(err => {
        console.log("err-----" + err);
        return -1;
      });
  }
  UpdateSettings(setting: Setting): Promise<any> {
    return this.proxy
      .post("InsUpdDebitAddition", {
        setting: setting,
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        if (res) {
          this.dailog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data: "פרטי תלמיד עדכנו בהצלחה ! "
          });
          return res;
        }
        // else this.dailog.open(dailog, {
        //   hasBackdrop: true,
        //   height: '200px',
        //   width: '300px',
        //   data: 'ארעה שגיאה בלתי צפויה '
        // });
      })
      .catch(err => {
        console.log("err-----" + err);
        return -1;
      });
  }

  GetDetailsToMonth(iUserId, iFamilyId, myDate): Promise<any> {
    myDate = new Date(myDate);
    return this.proxy
      .post("GetDetailsToMonth", {
        iUserId: iUserId,
        iFamilyId: iFamilyId,
        dDebitDate: myDate,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        if (res) {
          this.pDetails = res;
          return this.pDetails;
        } else {
          // this.dailog.open(dailog, {
          //   hasBackdrop: true,
          //   height: '200px',
          //   width: '300px',
          //   data: 'ארעה שגיאה בלתי צפויה '
          // });
          return null;
        }
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }

  //payment details
  saveAndInsertPaymentDetails(
    iUserId: number,
    iFamilyId: number,
    paymentdetails: paymentDetails,
    PaymentMethodToken,
    iCCCompanyType: number
  ): Promise<any> {
    paymentdetails.dPausedToDate = new Date(paymentdetails.dPausedToDate);
    // if(paymentdetails.nFixedAmount=="")

    // paymentdetails.dExpirationDate = new Date(paymentdetails.dExpirationDate);
    console.log("payment" + JSON.stringify(paymentdetails));
    return this.proxy
      .post("saveAndInsertPaymentDetails", {
        iUserId: iUserId,
        iFamilyId: iFamilyId,
        paymentDetails: paymentdetails,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId,
        lPaymentMethodToken: PaymentMethodToken,
        iCCCompanyType: iCCCompanyType
      })
      .then(res => {
        if (res) {
          this.dailog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data: "פרטי תשלום עודכנו בהצלחה"
          });
          return true;
        } else if (res == -1) {
          this.dailog.open(dailog, {
            hasBackdrop: true,
            height: '200px',
            width: '300px',
            data: 'ארעה שגיאה בלתי צפויה '
          });
          return null;
        }
        else if (res == -2) {
          this.dailog.open(dailog, {
            hasBackdrop: true,
            height: '200px',
            width: '300px',
            data: 'פרטי אשראי שגויים '
          });
          return null;
        }
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }

  GetPaymentDetails(iUserId: number, iFamilyId: number, bCanceled: boolean): Promise<any> {
    return this.proxy
      .post("GetPaymentDetails", {
        iUserId: iUserId,
        iFamilyId: iFamilyId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId,
        bCanceled: bCanceled
      })
      .then(res => {
        if (res) {
          return res;
        } else if (res == null) {
          this.dailog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data: "לא נמצאו רשומות"
          });
          return null;
        } else {
          // this.dailog.open(dailog, {
          //   hasBackdrop: true,
          //   height: '200px',
          //   width: '300px',
          //   data: 'ארעה שגיאה בלתי צפויה '
          // });
          return null;
        }
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }

  DeletePaymentDetails(iPaymentMethodId: number): Promise<any> {
    return this.proxy
      .post("DeletePaymentDetails", {
        iUserId: this.user.iUserId,
        iPaymentMethodId: iPaymentMethodId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        if (res) return res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return -1;
      });
  }

  GetSetting(): Promise<any> {
    return this.proxy
      .post("GetSettings", {
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        if (res) return res;
        // else this.dailog.open(dailog, {
        //   hasBackdrop: true,
        //   height: '200px',
        //   width: '300px',
        //   data: 'ארעה שגיאה בלתי צפויה '
        // });
      })
      .catch(err => {
        console.log("err-----" + err);
        return -1;
      });
  }
  GetDebitDays(): Promise<any> {
    return this.proxy
      .post("GetDebitDays", {
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        if (res) return res;
        // else this.dailog.open(dailog, {
        //   hasBackdrop: true,
        //   height: '200px',
        //   width: '300px',
        //   data: 'ארעה שגיאה בלתי צפויה '
        // });
      })
      .catch(err => {
        console.log("err-----" + err);
        return -1;
      });
  }
  UpdateSetting(setting: Setting) {
    this.proxy
      .post("UpdateSettings", {
        setting: setting,
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        if (res) {
          this.dailog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data: "פרטים התעדכנו בהצלחה!"
          });
          return res;
        } else
          this.dailog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data: "ארעה שגיאה בלתי צפויה "
          });
      })
      .catch(err => {
        console.log("err-----" + err);
      });
  }

  GetYears(): Promise<any> {
    return this.proxy
      .post("GetYears", {
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        if (res) {
          return res;
        }
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  GetYearCodeTable(): Promise<any> {
    return this.proxy
      .post("GetYearCodeTable", {
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide
      })
      .then(res => {
        if (res) {
          return res;
        }
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }

  GetBranches(): Promise<any> {
    return this.proxy
      .post("GetBranches", {
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId,
        iBranchId: this.user.iBranchId
      })
      .then(res => {
        if (res) {
          return res;
        }
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  GetInstitutions(): Promise<any> {
    return this.proxy
      .post("GetInstitution", {
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        if (res) {
          return res;
        }
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  GetRates(iRateTypeId: number): Promise<any> {
    return this.proxy
      .post("GetRates", {
        iRateTypeId: iRateTypeId,
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        if (res) {
          return res;
        }
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  GetAssociation(): Promise<any> {
    return this.proxy
      .post("GetAssociation", {
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        if (res) {
          return res;
        }
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }

  InsUpdYears(year: Year): Promise<any> {
    if (year.dBeginDate) year.dBeginDate = new Date(year.dBeginDate);
    if (year.dEndDate) year.dEndDate = new Date(year.dEndDate);
    return this.proxy
      .post("InsUpdYears", {
        year: year,
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        if (res) {
          this.dailog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data: "!פרטים התעדכנו בהצלחה"
          });
          return res;
        }
        // else this.dailog.open(dailog, {
        //   hasBackdrop: true,
        //   height: '200px',
        //   width: '300px',
        //   data: 'ארעה שגיאה בלתי צפויה '
        // });
      })
      .catch(err => {
        console.log("err-----" + err);
      });
  }

  InsUpdClasses(Classes: Classes): Promise<any> {
    return this.proxy
      .post("InsUpdClasses", {
        classes: Classes,
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        if (res) {
          if (res == -2)
            this.dailog.open(dailog, {
              hasBackdrop: true,
              height: "200px",
              width: "300px",
              data: "קיים כבר כיתה במספר זה"
            });
          else if (res == -3)
            this.dailog.open(dailog, {
              hasBackdrop: true,
              height: "200px",
              width: "300px",
              data: "קיים כבר כיתה בשם זה"
            });
          else
            this.dailog.open(dailog, {
              hasBackdrop: true,
              height: "200px",
              width: "300px",
              data: "!פרטים התעדכנו בהצלחה"
            });
          return res;
        }
        // else this.dailog.open(dailog, {
        //   hasBackdrop: true,
        //   height: '200px',
        //   width: '300px',
        //   data: 'ארעה שגיאה בלתי צפויה '
        // });
      })
      .catch(err => {
        console.log("err-----" + err);
      });
  }

  InsUpdDiscounts(Discont: Discont): Promise<any> {
    return this.proxy
      .post("InsUpdDiscounts", {
        discounts: Discont,
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        if (res) {
          this.dailog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data: "!פרטים התעדכנו בהצלחה"
          });
          return res;
        }
        // else this.dailog.open(dailog, {
        //   hasBackdrop: true,
        //   height: '200px',
        //   width: '300px',
        //   data: 'ארעה שגיאה בלתי צפויה '
        // });
      })
      .catch(err => {
        console.log("err-----" + err);
      });
  }
  InsUpdTransportRoute(transportRoute: TransportRoute): Promise<any> {
    return this.proxy
      .post("InsUpdTransportRoute", {
        transportRoute: transportRoute,
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        if (res) {
          if (res == -2)
            this.dailog.open(dailog, {
              hasBackdrop: true,
              height: "200px",
              width: "300px",
              data: "קיים כבר הסעה בשם זה"
            });
          else
            this.dailog.open(dailog, {
              hasBackdrop: true,
              height: "200px",
              width: "300px",
              data: "!פרטים התעדכנו בהצלחה"
            });
          return res;
        }
        // else this.dailog.open(dailog, {
        //   hasBackdrop: true,
        //   height: '200px',
        //   width: '300px',
        //   data: 'ארעה שגיאה בלתי צפויה '
        // });
      })
      .catch(err => {
        console.log("err-----" + err);
      });
  }

  GetDiscounts(): Promise<any> {
    return this.proxy
      .post("GetDiscounts", {
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId,
        iBranchId: this.user.iBranchId
      })
      .then(res => {
        if (res) {
          return res;
        }
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  GetTransportRoutes(): Promise<any> {
    return this.proxy
      .post("GetTransportRoutes", {
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId,
        iBranchId: this.user.iBranchId
      })
      .then(res => {
        if (res) {
          return res;
        }
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  GetClasses(): Promise<any> {
    return this.proxy
      .post("GetClasses", {
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId,
        iBranchId: this.user.iBranchId
      })
      .then(res => {
        if (res) {
          return res;
        }
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }

  InsUpdRates(Rates: Rate): Promise<any> {
    return this.proxy 
      .post("InsUpdRates", {
        rate: Rates,
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        if (res == -2) {
          this.dailog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data: "קיים תעריף בשם זה "
          });
        } else if (res) {
          this.dailog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data: "!פרטים התעדכנו בהצלחה"
          });
          return res;
        }
        // else this.dailog.open(dailog, {
        //   hasBackdrop: true,
        //   height: '200px',
        //   width: '300px',
        //   data: 'ארעה שגיאה בלתי צפויה '
        // });
      })
      .catch(err => {
        console.log("err-----" + err);
      });
  }
  InsUpdBranches(branch: Branch): Promise<any> {
    return this.proxy
      .post("InsUpdBranches", {
        branch: branch,
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        if (res) {
          if (res == -2)
            this.dailog.open(dailog, {
              hasBackdrop: true,
              height: "200px",
              width: "300px",
              data: "קיים כבר סניף בשם זה"
            });
          else
            this.dailog.open(dailog, {
              hasBackdrop: true,
              height: "200px",
              width: "300px",
              data: "!פרטים התעדכנו בהצלחה"
            });
          return res;
        }
        // else this.dailog.open(dailog, {
        //   hasBackdrop: true,
        //   height: '200px',
        //   width: '300px',
        //   data: 'ארעה שגיאה בלתי צפויה '
        // });
      })
      .catch(err => {
        console.log("err-----" + err);
      });
  }
  InsUpdAssociations(Association: Association): Promise<any> {
    return this.proxy
      .post("InsUpdAssociations", {
        association: Association,
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId,
        iCCCompanyType: this.default1.iCCCompanyType
      })
      .then(res => {
        if (res > 0) {
          this.dailog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data: "!פרטים התעדכנו בהצלחה"
          });

          return res;
        } else if (res == -2)
          this.dailog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data: "יש בעיה בפרטי המסוף של עמותה זו "
          });
        else if (res == -3)
          this.dailog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data: "קיימת עמותה בשם זה "
          });
        // else this.dailog.open(dailog, {
        //   hasBackdrop: true,
        //   height: '200px',
        //   width: '300px',
        //   data: 'ארעה שגיאה בלתי צפויה '
        // });
      })
      .catch(err => {
        console.log("err-----" + err);
      });
  }

  ClassesUpgrading(iYearId): Promise<any> {
    return this.proxy
      .post("ClassesUpgrading", {
        iYearId: iYearId,
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        if (res) {
          this.GetDefault();
          this.dailog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data: "!פרטים התעדכנו בהצלחה"
          });
          return res;
        }
      })
      .catch(err => {
        console.log("err-----" + err);
      });
  }
  UpdDefault(myDefault: Default): Promise<any> {
    return this.proxy
      .post("UpdDefault", {
        Default: myDefault,
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        if (res) {
          this.GetDefault();
          this.dailog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data: "!פרטים התעדכנו בהצלחה"
          });
          return res;
        }
      })
      .catch(err => {
        console.log("err-----" + err);
      });
  }

  InsUpdDebitDay(debitDay: DebitDay): Promise<any> {
    return this.proxy
      .post("InsUpdDebitDay", {
        debitDay: debitDay,
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        if (res == -2) {
          this.dailog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data: "יש יום חיוב זה "
          });
        } else if (res) {
          this.dailog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data: "!פרטים התעדכנו בהצלחה"
          });
        }

        // else this.dailog.open(dailog, {
        //   hasBackdrop: true,
        //   height: '200px',
        //   width: '300px',
        //   data: 'ארעה שגיאה בלתי צפויה '
        // });
        return res;
      })
      .catch(err => {
        console.log("err-----" + err);
      });
  }

  dataExport(): Promise<any> {
    return this.proxy
      .post("GetFromExcel", {
        iInstitutionId: this.user.iInstitutionId,
        iUserId: this.user.iUserId,
        iYearId: this.iYearId,
        nvGuide: this.user.nvGuide
      })
      .then(res => {
        if (res) {
          return res;
        }
        // else this.dailog.open(dailog, {
        //   hasBackdrop: true,
        //   height: '200px',
        //   width: '300px',
        //   data: 'ארעה שגיאה בלתי צפויה '
        // });
      })
      .catch(err => {
        console.log("err-----" + err);
      });
  }
  GetCities(): Promise<any> {
    return this.proxy
      .post("GetCities", {
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        if (res) {
          return res;
        }
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  LegalTz(c: FormControl) {
    let tot = 0;
    let tz = new String(c.value);
    for (let i = 0; i < 8; i++) {
      let x = ((i % 2) + 1) * parseInt(tz.charAt(i));
      if (x > 9) {
        let x1 = x.toString();
        x = parseInt(x1.charAt(0)) + parseInt(x1.charAt(1));
      }
      tot += x;
    }
    return (tot + parseInt(tz.charAt(8))) % 10 === 0
      ? null
      : {
        LegalTz: {
          valid: false
        }
      };
  }

  GetCreditCodeTables(isSequence: number ) {
    return this.proxy
      .post("GetCreditCodeTables", {
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iFamilyId: this.currentiFamilyId,
        iInstitutionId: this.user.iInstitutionId,
        isSequence: isSequence
      })
      .then(res => {
        if (res) {
          return res;
        }
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }

  GetCodeTablesByBranch() {
    return this.proxy
      .post("GetCodeTablesByBranch", {
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId,
        iBranchId: this.user.iBranchId
      })
      .then(res => {
        if (res) {
          return res;
        }
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }

  UpdClassChildren(iBranchId: number, iClassId, lChildYears, fieldsToChange: FieldsToChange): Promise<any> {
    return this.proxy
      .post("UpdClassChildren", {
        lChildYears: lChildYears,
        iUserId: this.user.iUserId,
        // iClassId: iClassId,
        // iBranchId: iBranchId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId,
        fieldsToChange: fieldsToChange
      })
      .then(res => {
        if (res == true)
          this.dailog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data: "!פרטים התעדכנו בהצלחה"
          });
        return res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }

  UpdInstitutionSymbolChildren(lChildYears, nvInstitutionSymbol) {
    return this.proxy
      .post("UpdInstitutionSymbolChildren", {
        iUserId: this.user.iUserId,
        lChildYears: lChildYears,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId,
        nvInstitutionSymbol: nvInstitutionSymbol

      })
      .then(res => {
        if (res == true)
          this.dailog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data: "!פרטים התעדכנו בהצלחה"
          });
        return res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }

  GetUpdateClassChildren(iBranchId: number, iYearId: number): Promise<any> {
    return this.proxy
      .post("GetUpdateClassChildren", {
        iUserId: this.user.iUserId,
        iYearId: iYearId,
        iBranchId: iBranchId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }

  GetDebitDetails(dDebitMonth: Date, iDebitDay: number): Promise<any> {
    return this.proxy
      .post("GetDebitDetails", {
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iDebitDay: iDebitDay,
        dDebitMonth: dDebitMonth,
        iFamilyId: this.currentiFamilyId,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }

  GetGrandParent(): Promise<any> {
    return this.proxy
      .post("GetGrandParent", {
        iUserId: this.user.iUserId,
        iFamilyId: this.currentiFamilyId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  GetDebitReport(dFromDate): Promise<any> {
    dFromDate = new Date(dFromDate);
    return this.proxy
      .post("GetDebitReport", {
        dFromDate: dFromDate,
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId,
        iBranchId: this.user.iBranchId
      })
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  GetCreditReport(dFromDate): Promise<any> {
    dFromDate = new Date(dFromDate);
    return this.proxy
      .post("GetCreditReport", {
        dFromDate: dFromDate,
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId,
        iBranchId: this.user.iBranchId
      })
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  GetLastDebitDateFamily(): Promise<any> {
    return this.proxy
      .post("GetLastDebitDateFamily", {
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iFamilyId: this.currentiFamilyId
      })
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  InsUpdCheck(lManualCredit: Array<FamilyCredit>) {
    for (let i = 0; i < lManualCredit.length; i++) {
      if (lManualCredit[i].dCreditDate != null)
        lManualCredit[i].dCreditDate = new Date(lManualCredit[i].dCreditDate);
      if (lManualCredit[i].dCheckDate != null)
        lManualCredit[i].dCheckDate = new Date(lManualCredit[i].dCheckDate);
      if (lManualCredit[i].dTransactDate != null)
        lManualCredit[i].dTransactDate = new Date(
          lManualCredit[i].dTransactDate
        );
    }
    return this.proxy
      .post("InsUpdCheck", {
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iFamilyId: this.currentiFamilyId,
        lChecks: lManualCredit,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        if (res) {
          if (res == 'success') {
            this.dailog.open(dailog, {
              hasBackdrop: true,
              height: "200px",
              width: "300px",
              data: "הנתונים התעדכנו בהצלחה "
            });
          }
          else {
            if (res == 'error') {
              this.dailog.open(dailog, {
                hasBackdrop: true,
                height: "200px",
                width: "300px",
                data: "ארעה שגיאה "
              });
            } else {
              this.dailog.open(dailog, {
                hasBackdrop: true,
                height: "200px",
                width: "300px",
                data: "הנתונים התעדכנו בהצלחה : " + res
              });
            }
          }
          return res;
        }
      })
      .catch(err => {
        console.log("err-----" + err);
      });
  }
  InsUpdCredit(lManualCredit: Array<FamilyCredit>) {
    for (let i = 0; i < lManualCredit.length; i++) {
      if (lManualCredit[i].dCreditDate != null)
        lManualCredit[i].dCreditDate = new Date(lManualCredit[i].dCreditDate);
      if (lManualCredit[i].dCheckDate != null)
        lManualCredit[i].dCheckDate = new Date(lManualCredit[i].dCheckDate);
      if (lManualCredit[i].dTransactDate != null)
        lManualCredit[i].dTransactDate = new Date(
          lManualCredit[i].dTransactDate
        );
    }
    return this.proxy
      .post("InsUpdCredit", {
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iFamilyId: this.currentiFamilyId,
        lUpdateCredits: lManualCredit,
        iInstitutionId: this.user.iInstitutionId,
        iCCCompanyType: this.default1.iCCCompanyType
      })
      .then(res => {
        if (res) {
          if (res == 'success') {
            this.dailog.open(dailog, {
              hasBackdrop: true,
              height: "200px",
              width: "300px",
              data: "הנתונים התעדכנו בהצלחה "
            });
          }
          else {
            if (res == 'error') {
              this.dailog.open(dailog, {
                hasBackdrop: true,
                height: "200px",
                width: "300px",
                data: "ארעה שגיאה "
              });
            }
            else if (res.includes("credit card error:")) {

              res = res.replace("credit card error:", "");
              this.dailog.open(dailog, {
                hasBackdrop: true,
                height: "200px",
                width: "300px",
                data: res
              });
              return null;
            }
            else {
              this.dailog.open(dailog, {
                hasBackdrop: true,
                height: "200px",
                width: "300px",
                data: "הנתונים התעדכנו בהצלחה : " + res
              });
            }
          }
          return res;
        }
      })
      .catch(err => {
        console.log("err-----" + err);
      });
  }

  GetDefault() {
    return this.proxy
      .post("GetDefault", {
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        if (res) {
          this.default1 = res;
          this.iYearId = this.default1.iYearId;
          localStorage.setItem(
            "familyStatuses",
            JSON.stringify(this.default1.lFamilyStatus)
          );
          localStorage.setItem(
            "dDebitFamilyFromDate",
            JSON.stringify(this.default1.dDebitFamilyFromDate)
          );

          return res;
        }
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }

  CalculateDiscounts() {
    return this.proxy
      .post("CalculateDiscounts", {
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId,
        iYearId: this.iYearId
      })
      .then(res => {
        if (res) {
          this.dailog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data: "פרטי הנחות עודכנו בהצלחה"
          });
          return res;
        }
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  GetReportsCodeTable() {
    return this.proxy
      .post("GetReportsCodeTable", {
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        if (res) {
          return res;
        }
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  GetReports(iReportId: number) {
    return this.proxy
      .post("GetReports", {
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId,
        iReportId: iReportId
      })
      .then(res => {
        if (res) {
          return res;
        }
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }

  GetFamilyContactsReport() {
    return this.proxy
      .post("GetFamilyContactsReport", {
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        if (res) {
          return res;
        }
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }

  InsDebitAdditionByClass(debitAdditionByClass, lChildren) {
    if (debitAdditionByClass) {
      debitAdditionByClass.dDebitMonth = new Date(
        debitAdditionByClass.dDebitMonth
      );
    }
    return this.proxy
      .post("InsDebitAdditionByClass", {
        debitAdditionByClass: debitAdditionByClass,
        lChildren: lChildren,
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        if (res) {
          this.dailog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data: "פרטי תשלום עודכנו בהצלחה"
          });
          return res;
        }
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  GetDetailedAccountSheetForParent(date) {
    date = new Date(date);
    return this.proxy
      .post("GetDetailedAccountSheetForParent", {
        dFromDate: date,
        iUserId: this.user.iUserId,
        iFamilyId: this.currentiFamilyId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        if (res) {
          return res;
        }
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  GetAccountSheetForParent(date) {
    date = new Date(date);
    return this.proxy
      .post("GetAccountSheetForParent", {
        dFromDate: date,
        iUserId: this.user.iUserId,
        iFamilyId: this.currentiFamilyId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        if (res) {
          return res;
        }
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  UpdMobilityToAssociations(iAssociationId, lFamilies) {
    return this.proxy
      .post("UpdMobilityToAssociations", {
        iAssociationId: iAssociationId,
        lFamilies: lFamilies,
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        if (res) {
          this.dailog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data: "בוצע בהצלחה"
          });
          return res;
        }
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  GetMobilityToAssociations(dDebitMonth, iDebitDay) {
    return this.proxy
      .post("GetMobilityToAssociations", {
        iDebitDay: iDebitDay,
        dDebitMonth: dDebitMonth,
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        if (res) {
          return res;
        }
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }

  UpdFileTransmission(iCreditDay: number, dCreditMonth: Date) {
    return this.proxy
      .post("UpdFileTransmission", {
        iCreditDay: iCreditDay,
        dCreditMonth: dCreditMonth,
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        if (res) {
          this.dailog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data: "בוצע בהצלחה"
          });
          return res;
        }
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  ResetMobilityToAssociations() {
    return this.proxy
      .post("ResetMobilityToAssociations", {
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        if (res) {
          this.dailog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data: "בוצע בהצלחה"
          });
          return res;
        }
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }

  CheckPeleCardStatus(uniqeCode) {
    return this.proxy
      .post("CheckPeleCardStatus", { nvUniqeCode: uniqeCode })
      .then(res => {
        if (res) {
          if (res == 1)
            this.dailog.open(dailog, {
              hasBackdrop: true,
              height: "200px",
              width: "300px",
              data: "פרטי תשלום עודכנו בהצלחה"
            });
          if (res == -2)
            this.dailog.open(dailog, {
              hasBackdrop: true,
              height: "200px",
              width: "300px",
              data: "שגיאה בשמירת פרטי אשראי"
            });
          return res;
        }
      })
      .catch(err => {
        console.log("err-----" + err);
      });
  }
  GetChildrenListForDebitAdditionByClass(iBranchId, iFromClassId, iToClassId) {
    return this.proxy
      .post("GetChildrenListForDebitAdditionByClass", {
        iBranchId: iBranchId,
        iFromClassId: iFromClassId,
        iToClassId: iToClassId,
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        return res;
      })
  }
  GetTokenPeleCard(nvCard, dExpirationDate) {
    let flag = 0;
    return this.proxy
      .post("GetTokenPeleCard", {
        cc: nvCard,
        date: dExpirationDate,
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        if (res) {
          res.forEach(element => {
            if (element.StatusCode > 0) {
              flag = 1;
            }
          });
          if (flag == 1) {
            this.dailog.open(dailog, {
              hasBackdrop: true,
              height: "300px",
              width: "400px",
              data:
                "יש בעיה בפרטי האשראי או בפרטים של אחת העמותות אנא בדוק נתונים והזן מחדש"
            });
            return null;
          }
          return res;
        }
      })
      .catch(err => {
        console.log("err-----" + err);
      });
  }
  GetTokenZCredit(nvCard, cvv, nvAccountIdentity, dExpirationDate) {
    let flag = "";
    return this.proxy
      .post("GetTokenZCredit", {
        nvCardNumber: nvCard,
        nvExpDate: dExpirationDate,
        nvAccountIdentity: nvAccountIdentity,
        nvCVV: cvv,
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        if (res) {
          res.forEach(element => {
            if (element.StatusCode > 0) {
              this.dailog.open(dailog, {
                hasBackdrop: true,
                height: "300px",
                width: "400px",
                data: element.ErrorMessage
              });
              return null;
            }
          });

          return res;
        }
      })
      .catch(err => {
        console.log("err-----" + err);
      });
  }

  InsUpdGrandParent(contact: FamilyContacts) {
    return this.proxy
      .post("InsUpdGrandParent", {
        iUserId: this.user.iUserId,
        iFamilyId: this.currentiFamilyId,
        grandParent: contact,
        nvGuide: this.user.nvGuide,
        iInstitutionId: this.user.iInstitutionId
      })
      .then(res => {
        if (res) {
          this.dailog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data: "!פרטים התעדכנו בהצלחה"
          });
          return res;
        }
        // else this.dailog.open(dailog, {
        //   hasBackdrop: true,
        //   height: '200px',
        //   width: '300px',
        //   data: 'ארעה שגיאה בלתי צפויה '
        // });
      })
      .catch(err => {
        console.log("err-----" + err);
      });
  }

  ImportToExcel(lImportToExcel: ImportToExcel[], iYearId): Promise<any> {
    return this.proxy
      .post("InsFromExcel", {
        lImportToExcel: lImportToExcel,
        iInstitutionId: this.user.iInstitutionId,
        iUserId: this.user.iUserId,
        iYearId: iYearId,
        nvGuide: this.user.nvGuide
      })
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }

  downloadFile(url: string, name: string, type: string) {
    // console.log(url);
    this.toDataUrl(url, function (base64) {
      let data = base64.split(",")[1];
      var binaryString = window.atob(data);
      var binaryLen = binaryString.length;
      var bytes = new Uint8Array(binaryLen);
      for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      data = bytes;

      var file = type ? new Blob([data], { type: type }) : new Blob([data]);
      var link = document.createElement("a");
      link.setAttribute("id", "linkDownload");
      link.href = window.URL.createObjectURL(file);
      link.download = name + (type ? "." + type : "");
      link.click();
      try {
        document.getElementById("linkDownload").remove();
      } catch (e) {
        //alert("הורדת הקובץ נכשלה");
        console.log(e);
      }
    });
  }

  toDataUrl(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }

  ReadFromExcel(target: DataTransfer, callBack) {
    
    try {
      var file = target.files[0];

      if (file == undefined) throw new Error("Cannot use multiple files");

      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        const bstr: string = e.target.result; //תוכן הקובץ
        var workbook = XLSX.read(bstr, { type: 'binary', cellDates: true, dateNF: 'dd/MM/yyyy;@' }); //המרה למשהו שהתוכנה יכולה לקרוא
        var headerNames: any = XLSX.utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[0]],
          { header: 1 }
        )[0];

        var data = XLSX.utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[0]], { header: 1 }
        );

        callBack(data);
      };
      reader.readAsBinaryString(file);
    } catch (error) {
      alert("טעינת הקובץ נכשלה");
      console.log(error);
    }
  }
  UpdChildFamily(family, id) {
    return this.proxy.post("UpdChildFamily", { iChildId: id, iFamilyId: family, iUserId: this.user.iUserId, nvGuide: this.user.nvGuide, iInstitutionId: this.user.iInstitutionId }).then(res => { return res })
  }
  CheckInsFromExcel(lImportToExcel: ImportToExcel[], iYearId): Promise<any> {
    return this.proxy
      .post("CheckInsFromExcel", {
        lImportToExcel: lImportToExcel,
        iInstitutionId: this.user.iInstitutionId,
        iUserId: this.user.iUserId,
        iYearId: iYearId,
        nvGuide: this.user.nvGuide
      })
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  printToPDF1(gridColumnApi, gridOptions, content, imageUrl) {
    if (gridColumnApi) {
      let lstColumn = gridColumnApi.getAllGridColumns();
      let i = 0;
      lstColumn.forEach(element => {
        if (element.colId == "iFamilyId")
          lstColumn.splice(i, 1)
        i++;
      });

      let lReportsDet = new Array<ReportsDet>();
      let reportsDet: ReportsDet;
      if (lstColumn)
        var string =
          '<DataTable xmlns="http://schemas.datacontract.org/2004/07/System.Data"><xs:schema id="NewDataSet" xmlns:xs="http://www.w3.org/2001/XMLSchema" xmlns="" xmlns:msdata="urn:schemas-microsoft-com:xml-msdata"><xs:element name="NewDataSet" msdata:IsDataSet="true" msdata:MainDataTable="dt" msdata:UseCurrentLocale="true"><xs:complexType><xs:choice minOccurs="0" maxOccurs="unbounded"><xs:element name="dt"><xs:complexType><xs:sequence>';

      lstColumn.forEach((col, index) => {
        reportsDet = new ReportsDet();
        if (col.visible == true) {
          reportsDet.nFieldWidth = col.actualWidth;
          reportsDet.nvFieldName = col.colId;
          reportsDet.nvFieldTitle = col.colDef.headerName;
          lReportsDet.push(reportsDet);
        }
      });
      lReportsDet.forEach((col, colIndex) => {
        string +=
          '<xs:element name="a' +
          colIndex +
          '" type="xs:string" minOccurs="0"/>';
      });
      string +=
        '</xs:sequence></xs:complexType></xs:element></xs:choice></xs:complexType></xs:element></xs:schema><diffgr:diffgram xmlns:diffgr="urn:schemas-microsoft-com:xml-diffgram-v1" xmlns:msdata="urn:schemas-microsoft-com:xml-msdata"><DocumentElement xmlns="">';
      gridOptions.gridApi.rowModel.rowsToDisplay.forEach(
        (element, dataIndex) => {
          string +=
            '<dt diffgr:id="dt' +
            dataIndex +
            '" msdata:rowOrder="' +
            dataIndex +
            '" diffgr:hasChanges="inserted">';

            lReportsDet.forEach((col, colIndex) => {
            let dataIndexPlusOne = dataIndex + 1;
            if (col.nvFieldName == "indexRow")
              string +=
                "<a" + colIndex + ">" + dataIndexPlusOne + "</a" + colIndex + ">";
            else
              string +=
                "<a" +
                colIndex +
                ">" ;
              if (element.data[col.nvFieldName] == null){string +=' '} else{string += element.data[col.nvFieldName]} ;
              string += 
              "</a" +
                colIndex +
                ">";
          });
          string += "</dt>";
        }
      );
      string += "</DocumentElement></diffgr:diffgram></DataTable>";
      this.PrintToPDF(lReportsDet, string, content, imageUrl)
        .then(res => {
          var link = document.createElement("a");
          link.download = res;
          link.href = AppProxy.baseUrl + "Files/" + res;
          // link.click();
          window.open(link.href);
        });
    }
  }


  PaymentToPDF(title, filePath): Promise<any> {
    return this.proxy
      .post("PaymentToPDF", {
        iUserId: this.user.iUserId,
        nvGuide: this.user.nvGuide,
        title: title,
        nvFilePath: filePath
      })
      .then(res => {
        var link = document.createElement("a");
        link.download = res;
        link.href = AppProxy.baseUrl + "Files/" + res;
        window.open(link.href);
        return res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  PaymentToPDF2(title, filePath): Promise<any> {
    return this.proxy
      .post("PaymentToPDF2", {
        title: title,
        nvFilePath: filePath
      })
      .then(res => {
        var link = document.createElement("a");
        link.download = res;
        link.href = AppProxy.baseUrl + "Files/" + res;
        window.open(link.href);
        return res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  // List<int> lChildStatusTypes, int iYearId, int iUserId, string nvGuide, int iInstitutionId, int iBranchId
  GetChildrenByClassReport() {
    // : Promise<any> 
    // return this.proxy
    //   .post("GetChildrenByClassReport", {
    //     lChildStatusTypes: this.lStatus,
    //     iYearId: this.iYearId,
    //     iUserId: this.user.iUserId,
    //     nvGuide: this.user.nvGuide,
    //     iInstitutionId: this.user.iInstitutionId,
    //     iBranchId: this.user.iBranchId
    //   })
    //   .then(res => {
    //     var link = document.createElement("a");
    //     link.download = res;
    //     link.href = AppProxy.baseUrl + "Files/" + res;
    //     window.open(link.href);
    //     return res;
    //   })
    //   .catch(err => {
    //     console.log("err-----" + err);
    //     return false;
    //   });
  }


  PrintToPDFByClass(lReportsDet: Array<ReportsDet>, lpdfByClass: Array<pdfByClass>): Promise<any> {
    return this.proxy
      .post("PrintToPDFByClass", {
        lReportsDet: lReportsDet,
        lpdfByClass: lpdfByClass
      })

      .then(res => {
        return res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }

  PrintReceiptFamily(iUserId: number, nvGuide: string, dCreditMonth: Date, iInstitutionId: number, iCreditId: number, iCheckId: number): Promise<any> {
    return this.proxy
      .post("PrintReceiptFamily", {
        iUserId: iUserId,
        nvGuide: nvGuide,
        dCreditMonth: dCreditMonth,
        iFamilyId: this.currentiFamilyId,
        iInstitutionId: iInstitutionId,
        iCreditId: iCreditId,
        iCheckId: iCheckId
      })
      .then(res => {
        if (res[0] == 0) {
          for (let i = 1; i < res.length; i++) {
            var link = document.createElement("a");
            let str = res[i];
            window.open(str);
          }
        }
        if (res[0] != 0) {
          this.dailog.open(dailog, {
            hasBackdrop: true,
            height: "200px",
            width: "300px",
            data: res[1]
          });
        }

        return res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }

  CreateReceipts(iUserId: number, nvGuide: string, iInstitutionId: number, dFromDate: Date, dToDate: Date): Promise<any> {
    return this.proxy
      .post("CreateReceipts", {
        iUserId: iUserId,
        nvGuide: nvGuide,
        iInstitutionId: iInstitutionId,
        dFromDate: dFromDate,
        dToDate: dToDate
      })
      .then(res => {
        if (res) {
          if (res == 0)
            this.dailog.open(dailog, {
              hasBackdrop: true,
              height: "200px",
              width: "300px",
              data: 'ארעה שגיאה בלתי צפויה '
            });
          if (res == 1)
            this.dailog.open(dailog, {
              hasBackdrop: true,
              height: "200px",
              width: "300px",
              data: "הקבלות הונפקו בהצלחה"
            });
          if (res < 0)
            this.dailog.open(dailog, {
              hasBackdrop: true,
              height: "200px",
              width: "300px",
              data: "ל " + res + "משפחות היתה שגיאה ביצירת הקבלה"

            });
        }

        return res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
  // (int iUserId, string nvGuide, int iInstitutionId)
  GetMonthesCodeTableForUpdateReturn(iUserId: number, nvGuide: string, iInstitutionId: number): Promise<any> {
    return this.proxy
      .post("GetMonthesCodeTableForUpdateReturn", {
        iUserId: iUserId,
        nvGuide: nvGuide,
        iInstitutionId: iInstitutionId
      })
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }

  PrintReceipts(iUserId: number, nvGuide: string, iInstitutionId: number, dFromDate: Date, dToDate: Date): Promise<any> {
    return this.proxy
      .post("PrintReceipts", {
        iUserId: iUserId,
        nvGuide: nvGuide,
        iInstitutionId: iInstitutionId,
        dFromDate: dFromDate,
        dToDate: dToDate
      })
      .then(res => {
        if (res) {
          if (res[0] == 0) {
            for (let i = 1; i < res.length; i++) {
              var link = document.createElement("a");
              let str = res[i];
              window.open(str);
            }
          }
          if (res[0] < 0) {
            this.dailog.open(dailog, {
              hasBackdrop: true,
              height: "200px",
              width: "300px",
              data: res[1]
            });

          }
        }
        return res;
      })
      .catch(err => {
        console.log("err-----" + err);
        return false;
      });
  }
}
