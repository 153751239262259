import {
  Component,
  OnInit,
  Input,
  Output,
  OnChanges,
  EventEmitter
} from "@angular/core";
import {
  trigger,
  state,
  style,
  animate,
  transition
} from "@angular/animations";
import { setInterval } from "timers";
@Component({
  selector: "newpopup",
  template: `
    <div *ngIf="visible" [style.z-index]="zIndex" class="dialog">
      <ng-content></ng-content>
      <button
        *ngIf="closable"
        (click)="close()"
        aria-label="Close"
        class="dialog__close-btn"
      >
        X
      </button>
    </div>
    <div *ngIf="visible" class="overlay" (click)="close()"></div>
  `,
  styles: [
    `
      .overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 499;
      }
      .dialog {
        position: fixed;
        right: 0;
        left: 0;
        top: 29%;
        margin-right: auto;
        margin-left: auto;
        min-height: 200px;
        width: 75%;
        min-width: 520px;
        background-color: #fff;
        padding: 38px;
        box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
          0 13px 19px 2px rgba(0, 0, 0, 0.14),
          0 5px 24px 4px rgba(0, 0, 0, 0.12);
      }

      .dialog__close-btn {
        border: 0;
        background: none;
        color: #2d2d2d;
        position: absolute;
        top: 8px;
        right: 8px;
        font-size: 1.2em;
        cursor: pointer;
        z-index: 99999;
      }
    `
  ],
  animations: [
    trigger("dialog", [
      transition("void => *", [
        style({ transform: "scale3d(.3, .3, .3)" }),
        animate(100)
      ]),
      transition("* => void", [
        animate(100, style({ transform: "scale3d(.0, .0, .0)" }))
      ])
    ])
  ]
})
export class newpopup implements OnInit {
  @Input() closable = true;
  @Input() visible: boolean;
  @Input() zIndex: any = 500;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}
  ngOnInit() {}
  close() {
    this.visible = false;
    this.visibleChange.emit(this.visible);
    // alert("לא שמרת, נתונים לא ישמרו")
  }
  ngOnChanges() {
    console.log(this.zIndex, this.visible);
  }
}
